import { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";

function ResetPassword({ history, match }) {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState({});
  const [banner, setBanner] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();

    if (password !== password2) {
      setPassword("");
      setPassword2("");
      setBanner({
        message: "The passwords do not match!",
        alertType: "danger",
      });
    }
    setTimeout(() => {
      setBanner({});
    }, 6500);

    let data = {
      password: password,
      resetToken: match.params.resetToken,
    };

    axios
      .post("/users/resetpassword", { data })
      .then((res) => {
        setBanner({
          message: "Password successfully changed. You can login now.",
          alertType: "success",
        });
        setPassword("");
        setPassword2("");
        setTimeout(() => {
          setBanner({});
        }, 6500);
      })
      .catch((err) => {
        setError(err.response.data);
        if (err.response.data.userNotFound) {
          setBanner({
            message: err.response.data.userNotFound,
            alertType: "danger",
          });
        }
        setTimeout(() => {
          setBanner({});
        }, 6500);
      });
  };

  return (
    <div className="ResetPassword">
      <Container>
        <h2 className="display-2 text-center">Reset password</h2>
        {banner.message && (
          <Alert className="text-center" variant={banner.alertType}>
            {banner.message}
          </Alert>
        )}
        <Form noValidate onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>New Password</b>
            </Form.Label>
            <Form.Control
              type="password"
              required
              id="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              tabIndex={1}
              isInvalid={!!error.password}
              className={classnames("", {
                invalid: error.password,
              })}
            />
            <Form.Control.Feedback type="invalid">
              {error.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Confirm password</b>
            </Form.Label>
            <Form.Control
              type="password"
              required
              id="password2"
              placeholder="Confirm password"
              onChange={(e) => setPassword2(e.target.value)}
              value={password2}
              tabIndex={1}
              isInvalid={!!error.password}
              className={classnames("", {
                invalid: error.password,
              })}
            />
            <Form.Control.Feedback type="invalid">
              {error.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">
            Reset password
          </Button>
        </Form>
        <div className="text-center">
          <p className="fs-5">
            Want to sign in? <Link to="/login">Login here!</Link>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default ResetPassword;
