import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Table } from "react-bootstrap";
import React from "react";
import axios from "axios";

function Directory() {
  // State Management
  const [isLoading, setLoading] = useState(true);
  const [memberData, setMemberDaata] = useState([]);
  const [search, setSearch] = React.useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    // Get user information
    axios
      .get("/miscfunctions/getMemberDirectoryInfo")
      .then((res) => {
        setMemberDaata(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const searchData = {
    nodes: memberData.filter(
      (item) =>
        item.Name.toLowerCase().includes(search.toLowerCase()) ||
        item.Committee.toLowerCase().includes(search.toLowerCase()) ||
        item.Class.toLowerCase().includes(search.toLowerCase()) ||
        item.Major.toLowerCase().includes(search.toLowerCase()),
    ),
  };

  const memberInformation = searchData.nodes.map((memberInfo, key) => (
    <tr key={key}>
      <th>{memberInfo.Name}</th>
      <th>{memberInfo.Class}</th>
      <th>{memberInfo.Committee}</th>
      <th>{memberInfo.Major}</th>
      <th>{memberInfo.Phone}</th>
      <th>
        {memberInfo.Instagram ? (
          <a href={"http://instagram.com/" + memberInfo.Instagram}>
            <img
              src="http://assets.stickpng.com/images/580b57fcd9996e24bc43c521.png"
              height="30"
              alt="instagram"
            ></img>
          </a>
        ) : (
          ""
        )}
      </th>
      <th>
        {memberInfo.LinkedIn ? (
          <a href={memberInfo.LinkedIn}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
              height="30"
              alt="linkedin"
            ></img>
          </a>
        ) : (
          ""
        )}
      </th>
    </tr>
  ));

  return (
    <div className="MyPoints">
      <Container>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <React.Fragment>
            <h2 className="display-4 text-center"> Member Directory </h2>
            <label htmlFor="search">
              Search:
              <input id="search" type="text" onChange={handleSearch} />
            </label>
            <Table striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Class</th>
                  <th>Committee</th>
                  <th>Major</th>
                  <th>Phone</th>
                  <th>Instagram</th>
                  <th>LinkedIn</th>
                </tr>
              </thead>
              <tbody>{memberInformation}</tbody>
            </Table>
          </React.Fragment>
        )}{" "}
      </Container>
    </div>
  );
}

Directory.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Directory);
