import { Container, Card, Badge } from "react-bootstrap";
import { React } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function LogCards(props) {
  let propDate =
    new Date(props.time).toLocaleDateString() +
    " " +
    new Date(props.time).toLocaleTimeString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });

  let badgeIcon = {
    Promotion: "success",
    Deletion: "danger",
    ChangeMember: "warning",
    Creation: "info",
    Edited: "info",
    Launched: "primary",
    Unlaunched: "secondary",
    Closed: "danger",
    Reset: "danger",
  };

  return (
    <div className="LogCards">
      <Container>
        <Card
          border={badgeIcon[props.type]}
          style={{ borderWidth: "2px" }}
          className="p-3 m-4 rounded shadow"
        >
          <Card.Body>
            <Card.Title>
              <Badge bg={badgeIcon[props.type]} className="m-2">
                {props.type}
              </Badge>
            </Card.Title>

            <Card.Text>
              {props.name} {props.description}
            </Card.Text>
            <Card.Text>{propDate}</Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

LogCards.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(LogCards);
