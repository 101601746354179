import {
  Container,
  Table,
  Spinner,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import React from "react";
import { Row, Col } from "react-bootstrap";

import LogCards from "./LogCards";

function Admin(props) {
  const [allUserPoints, setAllUserPoints] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showUserEvents, setShowUserEvents] = useState(false);
  const [userName, setUser] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userPoints, setUserPoints] = useState({});
  const [userMeetings, setUserMeetings] = useState(0);
  const [missedConsecutive, setMissedConsecutive] = useState(0);
  const [currentUserID, setCurrentUserID] = useState("");
  const [message, setMessage] = useState();
  const [totalMembers, setTotalMembers] = useState(0);
  const [sortedField, setSortedField] = React.useState(null);
  const [userTotalEvents, setUserTotalEvents] = useState([]);
  const [totalNonMembers, setTotalNonMembers] = useState(0);
  const [allNonMembers, setAllNonMembers] = useState([]);

  const [search, setSearch] = React.useState("");
  const [searchNonMember, setSearchNonMember] = React.useState("");

  const [itemsForConsole, setItemsForConsole] = useState([]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchNonMember = (event) => {
    setSearchNonMember(event.target.value);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentUserID("");
  };

  const handleCloseUserEvents = () => {
    setShowUserEvents(false);
    setCurrentUserID("");
    setUserTotalEvents([]);
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function successEvent(res) {
    if (res.status === 200) {
      setMessage("User successfully updated!");
    }
    setTimeout(() => {
      setMessage(null);
    }, 4000);
  }

  const handleSave = () => {
    if (!isEmpty(userPoints)) {
      let data = {
        points: userPoints,
        meetings: userMeetings,
        userID: currentUserID,
        role: userRole,
        missedConsecutive: missedConsecutive,
      };
      axios.post("/users/updateUserPoints", { data }).then((res) => {
        successEvent(res);
      });

      axios.post("/log/createLogItem", {
        params: {
          userID: props.auth.user.id,
          name: props.auth.user.fname + " " + props.auth.user.lname,
          type: "ChangeMember",
          time: new Date(Date.now()),
          Description: `adjusted member: ${userName}.`,
        },
      });
    }
    setShow(false);
    setUserPoints({});
    setCurrentUserID("");
  };

  const handleShow = (userID) => {
    axios
      .get("/users/getUserPoints", {
        params: {
          userID: userID,
        },
      })
      .then((res) => {
        setUser(res.data[0].lname + ", " + res.data[0].fname);
        setUserPoints(res.data[0].points);
        setUserMeetings(res.data[0].meetings);
        setMissedConsecutive(res.data[0].missedConsecutive);
        setUserRole(res.data[0].role);
      })
      .catch((err) => console.log(err));
    setCurrentUserID(userID);
    setLoading(false);
    setShow(true);
  };

  const showEvents = (userID) => {
    axios
      .get("/events/getEventsForCurrentUser", {
        params: {
          userID: userID,
        },
      })
      .then((res) => {
        console.log(res.data);
        setUserTotalEvents(res.data);
      })
      .catch((err) => console.log(err));
    setCurrentUserID(userID);
    setLoading(false);
    setShowUserEvents(true);
  };

  const incrementPoints = (type) => {
    if (type === "Meeting") {
      let copyStateMeetings = userMeetings + 1;
      setUserMeetings(copyStateMeetings);
    } else if (type === "role") {
      let copyStateRole = userRole;
      if (copyStateRole === "admin") {
        copyStateRole = "nm";
      } else if (copyStateRole === "ec") {
        copyStateRole = "admin";
      } else if (copyStateRole === "gc") {
        copyStateRole = "ec";
      } else {
        copyStateRole = "gc";
      }
      setUserRole(copyStateRole);
    } else if (type === "missedConsecutive") {
      let copyStateMissed = missedConsecutive + 1;
      setMissedConsecutive(copyStateMissed);
    } else {
      let copyState = { ...userPoints };
      copyState[type] = userPoints[type] + 1;
      setUserPoints(copyState);
    }
  };

  const decrementPoints = (type) => {
    if (type === "Meeting") {
      let copyStateMeetings = userMeetings - 1;
      setUserMeetings(copyStateMeetings);
    } else if (type === "role") {
      let copyStateRole = userRole;
      if (copyStateRole === "admin") {
        copyStateRole = "ec";
      } else if (copyStateRole === "ec") {
        copyStateRole = "gc";
      } else if (copyStateRole === "gc") {
        copyStateRole = "nm";
      } else {
        copyStateRole = "admin";
      }
      setUserRole(copyStateRole);
    } else if (type === "missedConsecutive") {
      let copyStateMissed = missedConsecutive - 1;
      setMissedConsecutive(copyStateMissed);
    } else {
      let copyState = { ...userPoints };
      copyState[type] = userPoints[type] - 1;
      setUserPoints(copyState);
    }
  };

  useEffect(() => {
    axios
      .get("/users/getAllUserPoints")
      .then((res) => {
        setTotalMembers(res.data.length);
        setAllUserPoints(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get("/users/getAllNonMembers")
      .then((res) => {
        setTotalNonMembers(res.data.length);
        setAllNonMembers(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get("/log/getAllConsoleItems")
      .then((res) => {
        setItemsForConsole(res.data);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  }, [show]);

  const nonMemberData = {
    nodes: allNonMembers.filter(
      (item) =>
        item.fname.toLowerCase().includes(searchNonMember.toLowerCase()) ||
        item.lname.toLowerCase().includes(searchNonMember.toLowerCase()),
    ),
  };
  let sortedNontMemberData = nonMemberData.nodes;

  const searchData = {
    nodes: allUserPoints.filter(
      (item) =>
        item.fname.toLowerCase().includes(search.toLowerCase()) ||
        item.lname.toLowerCase().includes(search.toLowerCase()),
    ),
  };

  let sortedData = searchData.nodes;
  if (sortedField !== null) {
    sortedData.sort((a, b) => {
      if (sortedField.key === "Meetings") {
        return sortedField.direction === "ascending"
          ? a.meetings - b.meetings
          : b.meetings - a.meetings;
      } else if (sortedField.key === "missedConsecutive") {
        return sortedField.direction === "ascending"
          ? a.missedConsecutive - b.missedConsecutive
          : b.missedConsecutive - a.missedConsecutive;
      } else if (sortedField.key === "Total Points") {
        let aTotal =
          a.points["Engineering Development"] +
          a.points["Career Fair"] +
          a.points["Miscellaneous"] +
          a.points["Outreach"] +
          a.points["Socials"] +
          a.points["Events"] +
          a.points["Committee"];

        let bTotal =
          b.points["Engineering Development"] +
          b.points["Career Fair"] +
          b.points["Miscellaneous"] +
          b.points["Outreach"] +
          b.points["Socials"] +
          b.points["Events"] +
          b.points["Committee"];

        return sortedField.direction === "ascending"
          ? aTotal - bTotal
          : bTotal - aTotal;
      } else {
        return sortedField.direction === "ascending"
          ? a.points[sortedField.key] - b.points[sortedField.key]
          : b.points[sortedField.key] - a.points[sortedField.key];
      }
    });
  }

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortedField === null) {
      setSortedField({ key, direction });
      return;
    }
    if (sortedField.key === key && sortedField.direction === "ascending") {
      direction = "descending";
    }
    setSortedField({ key, direction });
  };

  const userEventsTable = userTotalEvents.map((userEvent, key) => (
    <tr key={key}>
      <th>{userEvent.name}</th>
    </tr>
  ));

  const nonMembersTable = sortedNontMemberData.map((nonmember, key) => (
    <tr key={key}>
      <th className="col-md-5">
        {nonmember.lname}, {nonmember.fname}
      </th>
      <th className="col-md-2">
        <Row>
          <Col>
            <div style={{ float: "right" }}>
              <Button
                onClick={() =>
                  promoteNonMember(
                    nonmember._id,
                    nonmember.fname,
                    nonmember.lname,
                  )
                }
              >
                Promote
              </Button>
            </div>
          </Col>
          <Col>
            <div style={{ float: "left" }}>
              <Button
                className="btn-danger"
                onClick={() =>
                  deleteNonMember(
                    nonmember._id,
                    nonmember.fname,
                    nonmember.lname,
                  )
                }
              >
                Delete
              </Button>
            </div>
          </Col>
        </Row>
      </th>
    </tr>
  ));

  const promoteNonMember = (userID, fname, lname) => {
    let ans = window.confirm(
      "Are you sure you want to promote " + fname + " " + lname + "?",
    );
    if (!ans) {
      return;
    }
    axios
      .post("/users/promoteNonMember", {
        params: {
          userID: userID,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
          setMessage("User successfully updated!");
        }
        setTimeout(() => {
          setMessage(null);
        }, 4000);
      });
    axios.post("/log/createLogItem", {
      params: {
        userID: props.auth.user.id,
        name: props.auth.user.fname + " " + props.auth.user.lname,
        type: "Promotion",
        time: new Date(Date.now()),
        Description: `promoted member: ${fname + " " + lname}.`,
      },
    });
  };

  const deleteNonMember = (userID, fname, lname) => {
    let ans = window.confirm(
      "Are you sure you want to delete " +
        fname +
        " " +
        lname +
        "? This action cannot be undone.",
    );
    if (!ans) {
      return;
    }
    axios
      .delete("/users/deleteNonMember", {
        params: {
          userID: userID,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
          setMessage("User deleted!");
        }
        setTimeout(() => {
          setMessage(null);
        }, 4000);
      });
    axios.post("/log/createLogItem", {
      params: {
        userID: props.auth.user.id,
        name: props.auth.user.fname + " " + props.auth.user.lname,
        type: "Deletion",
        time: new Date(Date.now()),
        Description: `deleted member: ${fname + " " + lname}.`,
      },
    });
  };

  const deleteAllNonMembers = () => {
    let ans = window.confirm(
      "Are you sure you want to delete all non-members? This action cannot be undone.",
    );
    if (!ans) {
      return;
    }
    axios.delete("/users/deleteAllNonMembers").then((res) => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  };

  const userPointsTable = sortedData.map((userPoint, key) => (
    <tr key={key}>
      <th>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a class="link-primary" onClick={() => showEvents(userPoint._id)}>
          {userPoint.lname}, {userPoint.fname}{" "}
        </a>
      </th>
      <th>{userPoint.points["Events"]}</th>
      <th>{userPoint.points["Socials"]}</th>
      <th>{userPoint.points["Outreach"]}</th>
      <th>{userPoint.points["Miscellaneous"]}</th>
      <th>{userPoint.points["Career Fair"]}</th>
      <th>{userPoint.points["Engineering Development"]}</th>
      <th>{userPoint.points["Committee"]}</th>
      <th>
        {userPoint.points["Engineering Development"] +
          userPoint.points["Career Fair"] +
          userPoint.points["Miscellaneous"] +
          userPoint.points["Outreach"] +
          userPoint.points["Socials"] +
          userPoint.points["Events"] +
          userPoint.points["Committee"]}
      </th>
      <th>{userPoint.meetings}</th>
      <th>{userPoint.missedConsecutive}</th>
      <th>
        <Button onClick={() => handleShow(userPoint._id)}>Edit</Button>
      </th>
    </tr>
  ));

  const resetMemberPoints = () => {
    downloadExcelSheet();
    let ans = window.confirm(
      "Are you sure you want to reset all user points? This action cannot be undone.",
    );
    if (!ans) {
      return;
    }
    axios.post("/users/deleteAllUserPoints").then((res) => {
      if (res.status === 200) {
        window.location.reload();
      } else {
        setMessage("Error updating Members");
        setTimeout(() => {
          setMessage(null);
        }, 4000);
      }
    });
    axios.post("/log/createLogItem", {
      params: {
        userID: props.auth.user.id,
        name: props.auth.user.fname + " " + props.auth.user.lname,
        type: "Reset",
        time: new Date(Date.now()),
        Description: `reset all user points.`,
      },
    });
  };

  const downloadExcelSheet = () => {
    let csvContent =
      "First Name,Last Name,Meetings,Missed Consecutive,Career Fair,Engineering Development,Events,Miscellaneous,Outreach,Socials,Committee,Total Points\n";

    sortedData.forEach((item) => {
      let totalPoints =
        (item.points["Career Fair"] ?? 0) +
        (item.points["Engineering Development"] ?? 0) +
        (item.points["Events"] ?? 0) +
        (item.points["Miscellaneous"] ?? 0) +
        (item.points["Outreach"] ?? 0) +
        (item.points["Socials"] ?? 0) +
        (item.points["Committee"] ?? 0);
      csvContent += `${item.fname},${item.lname},${item.meetings},${item.missedConsecutive},${item.points["Career Fair"]},${item.points["Engineering Development"]},${item.points["Events"]},${item.points["Miscellaneous"]},${item.points["Outreach"]},${item.points["Socials"]},${item.points["Committee"]},${totalPoints}\n`;
    });

    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "MemberPoints.csv");

    document.body.appendChild(link);
    link.click();
  };

  const logItems = itemsForConsole.map((logItem) => (
    <LogCards
      userID={logItem.name}
      name={logItem.name}
      description={logItem.description}
      type={logItem.type}
      time={logItem.time}
    />
  ));

  return (
    <div className="Admin">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Editing <i>{userName}</i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container text-center d-inline">
            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("role")}>-</Button>
              </div>
              <div className="col-sm">
                <b>Current Role: </b>
                {userRole}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("role")}>+</Button>
              </div>
            </div>
            <br></br>

            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("Events")}>-</Button>
              </div>
              <div className="col-sm">
                <b>Events: </b>
                {userPoints["Events"]}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("Events")}>+</Button>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("Socials")}>-</Button>
              </div>
              <div className="col-sm">
                <b>Social: </b>
                {userPoints["Socials"]}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("Socials")}>+</Button>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("Outreach")}>-</Button>
              </div>
              <div className="col-sm">
                <b>Outreach: </b>
                {userPoints["Outreach"]}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("Outreach")}>+</Button>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("Miscellaneous")}>
                  -
                </Button>
              </div>
              <div className="col-sm">
                <b>Misc: </b>
                {userPoints["Miscellaneous"]}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("Miscellaneous")}>
                  +
                </Button>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("Career Fair")}>
                  -
                </Button>
              </div>
              <div className="col-sm">
                <b>Career Fair: </b>
                {userPoints["Career Fair"]}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("Career Fair")}>
                  +
                </Button>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-sm">
                <Button
                  onClick={() => decrementPoints("Engineering Development")}
                >
                  -
                </Button>
              </div>
              <div className="col-sm">
                <b>Engr Dev: </b>
                {userPoints["Engineering Development"]}
              </div>
              <div className="col-sm">
                <Button
                  onClick={() => incrementPoints("Engineering Development")}
                >
                  +
                </Button>
              </div>
            </div>

            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("Committee")}>-</Button>
              </div>
              <div className="col-sm">
                <b>Committee: </b>
                {userPoints["Committee"]}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("Committee")}>+</Button>
              </div>
            </div>

            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("Meeting")}>-</Button>
              </div>
              <div className="col-sm">
                <b>Meetings: </b>
                {userMeetings}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("Meeting")}>+</Button>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-sm">
                <Button onClick={() => decrementPoints("missedConsecutive")}>
                  -
                </Button>
              </div>
              <div className="col-sm">
                <b>Missed Consecutive: </b>
                {missedConsecutive}
              </div>
              <div className="col-sm">
                <Button onClick={() => incrementPoints("missedConsecutive")}>
                  +
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUserEvents} onHide={handleCloseUserEvents}>
        <Modal.Header closeButton>
          <Modal.Title>Attended Events:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container text-center d-inline">
            <center>
              <b>
                <tbody>{userEventsTable}</tbody>
              </b>
            </center>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserEvents}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <div className="meetingHeading text-center">
          <h2 className="display-2">Member Points and Meetings</h2>
          <b>Total members:</b> {totalMembers}
          <br></br>
        </div>

        <Row>
          <Col>
            <label htmlFor="search">
              Search:
              <input id="search" type="text" onChange={handleSearch} />
            </label>
          </Col>
          <Col>
            <div style={{ float: "right", padding: "10px" }}>
              <Button
                className="btn-success"
                onClick={() => downloadExcelSheet()}
                style={{ marginRight: "10px" }}
              >
                - Download Member Points
              </Button>

              <Button
                className="btn-danger"
                onClick={() => resetMemberPoints()}
                style={{ marginLeft: "10px" }}
              >
                - Reset All Members Points
              </Button>
            </div>
          </Col>
        </Row>
        <br></br>
        {message && (
          <Alert className="text-center" variant="success">
            {message}
          </Alert>
        )}
        {isLoading ? (
          <center>
            {" "}
            <Spinner animation="border" />{" "}
          </center>
        ) : (
          <React.Fragment>
            <div className="tablesForPoints">
              <Table striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Events")}
                      >
                        Event
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Socials")}
                      >
                        Social
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Outreach")}
                      >
                        Outreach
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Miscellaneous")}
                      >
                        Misc
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Career Fair")}
                      >
                        Career Fair
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Engineering Development")}
                      >
                        Engr Dev
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Committee")}
                      >
                        Committee
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Total Points")}
                      >
                        Total Points
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("Meetings")}
                      >
                        Meetings Attended
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        onClick={() => requestSort("missedConsecutive")}
                      >
                        Missed Consecutive
                      </button>
                    </th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>{userPointsTable}</tbody>
              </Table>
            </div>
            <br></br>
            <br></br>

            <div className="meetingHeading text-center">
              <h2 className="display-2">Non-Members</h2>
              <b>Total non-members:</b> {totalNonMembers}
            </div>
            <label htmlFor="search">
              Search:
              <input id="search" type="text" onChange={handleSearchNonMember} />
            </label>
            {message && (
              <Alert className="text-center" variant="success">
                {message}
              </Alert>
            )}

            <Button
              variant="danger btn-sm"
              style={{ float: "right" }}
              onClick={() => deleteAllNonMembers()}
            >
              - Delete All Non-Members
            </Button>
            <div
              className="tablesForPoints"
              style={{ overflowY: "auto", maxHeight: "800px" }}
            >
              <Table striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{nonMembersTable}</tbody>
              </Table>
            </div>
            <br></br>
            <br></br>

            <div className="meetingHeading text-center">
              <h2 className="display-2">Console Log</h2>
            </div>

            <div
              style={{
                height: "350px",
                borderRadius: "15px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
                overflowY: "auto",
              }}
            >
              {logItems.reverse()}
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
}

Admin.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Admin);
