import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, CardGroup, Spinner, Alert, Button } from "react-bootstrap";
import EventCards from "../Events/EventCards";
import axios from "axios";
import { useSpring, animated } from "react-spring";

function Meetings(props) {
  // state management
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState();
  const [alertType, setAlertType] = useState("danger");
  const [userMeetings, setUserMeetings] = useState([]);
  const [allMeetings, setAllMeetings] = useState([]);
  const springProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  useEffect(() => {
    // get available meetings
    if (props.auth.user.role === "admin") {
      axios.get("/events/getAllMeetingsOpen").then((res) => {
        setAllMeetings(res.data);
      });
    }
    axios
      .get("/events/getActiveMeetingsForUser", {
        params: {
          userID: props.auth.user.id,
        },
      })
      .then((res) => setUserMeetings(res.data))
      .catch((err) => console.log(err));

    setLoading(false);
  }, [props.auth.user.id, props.auth.user.role]);

  function reRender(eventID, success) {
    if (success) {
      let filteredArray = userMeetings.filter((item) => item._id !== eventID);
      setUserMeetings(filteredArray);
      setAlertType("success");
      setMessage("Successfully signed into the meeting!");
    } else {
      setAlertType("danger");
      setMessage("Incorrect password!");
    }
    setTimeout(() => {
      setMessage(null);
    }, 6500);
  }

  const eventsForUser = userMeetings.map((event) => (
    <EventCards
      name={event.name}
      description={event.description}
      key={event._id}
      type={event.type}
      eventKey={event._id}
      removeEventReRender={reRender}
      location={event.location}
      from_time={event.from_time}
      to_time={event.to_time}
      points={event.points}
      canEdit={
        props.auth.user.role === "admin" ||
        event.createdBy === props.auth.user.id
      }
      createdBy={event.createdBy}
      password={event.password}
    />
  ));

  eventsForUser.reverse();

  const deleteAllMeetings = () => {
    let ans = window.confirm(
      "Are you sure you want to delete all meetings? This action is unreversible!",
    );
    if (!ans) {
      return;
    }
    axios.delete("/events/deleteAllMeetings").then((res) => {
      window.location.reload();
    });
  };

  return (
    <div className="Meetings">
      <Container>
        <h4 className="display-4 text-center">Available Meetings</h4>
        {message && (
          <Alert className="text-center" variant={alertType}>
            {message}
          </Alert>
        )}
        {isLoading ? (
          <center>
            {" "}
            <Spinner animation="border" />{" "}
          </center>
        ) : (
          <animated.div style={springProps}>
            {props.auth.user.role === "admin" && allMeetings.length !== 0 && (
              <center>
                <Button
                  className="btn-danger"
                  onClick={() => deleteAllMeetings()}
                >
                  - delete all meetings
                </Button>
                <br></br>
              </center>
            )}
            <CardGroup>{eventsForUser}</CardGroup>
            {eventsForUser.length === 0 ? (
              <h1 className="display-6 text-center">
                {" "}
                Looks like there aren't any available meetings right now
              </h1>
            ) : (
              <p></p>
            )}
          </animated.div>
        )}
      </Container>
    </div>
  );
}

Meetings.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Meetings);
