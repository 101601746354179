import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./manager.css";
import axios from "axios";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";

function Manager(props) {
  const history = useHistory();
  // State Management
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("Events");
  const [points, setPoints] = useState("1");
  const [message, setMessage] = useState();
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [location, setLocation] = useState("");
  const [error, setError] = useState({});
  let [shiftEvent, setShiftEvents] = useState(false);
  let [numShifts, setNumShifts] = useState(1);
  const [editingEvent, setEdititingEvent] = useState(false);

  const editData = history.location.state;

  useEffect(() => {
    if (editData) {
      setEdititingEvent(true);
      setName(editData.eventProps.name);
      setDescription(editData.eventProps.description);
      setLocation(editData.eventProps.location);
      setPassword(editData.eventProps.password);
      setType(editData.eventProps.type);
      setPoints(editData.eventProps.points);
      setShiftEvents(editData.eventProps.shiftsEvent);
      setNumShifts(editData.eventProps.numShifts);

      if (editData.eventProps.shiftsEvent) {
        document.getElementById("gridcheckbox").checked = true;
      }

      document.getElementById("eventType").value = editData.eventProps.type;
    }
  }, [editData]);

  const onSubmit = (e) => {

    if (editingEvent) {
      let eventPoints;
      if (type === "Meeting") {
        eventPoints = 0;
        shiftEvent = false;
      } else {
        eventPoints = parseInt(points);
      }
      if (shiftEvent === false) {
        numShifts = 0;
      }
      const eventData = {
        name: name,
        description: description,
        password: password,
        type: type,
        points: eventPoints,
        location: location,
        fromTime: fromTime,
        toTime: toTime,
        createdBy: editData.eventProps.createdBy,
        shiftsEvent: shiftEvent,
        numShifts: numShifts,
        eventID: editData.eventProps.eventID,
      };
      axios
        .post("/events/updateEvent", eventData)
        .then((res) => {
          if (res.status === 200) {
            if (type !== "Meeting") {
              history.push("/events");
            } else {
              history.push("/meetings");
            }
          }
        })
        .catch((err) => {
          setError(err.response.data);
          if (err.response.data.invalidTimes) {
            setMessage({
              variant: "danger",
              data: err.response.data.invalidTimes,
            });
            setTimeout(() => {
              setMessage(null);
            }, 6500);
          }
        });

      axios.post("/log/createLogItem", {
        params: {
          userID: props.auth.user.id,
          name: props.auth.user.fname + " " + props.auth.user.lname,
          type: "Edited",
          time: new Date(Date.now()),
          Description: `edited event ${name}.`,
        },
      });
    } else {
      let eventPoints;
      if (type === "Meeting") {
        eventPoints = 0;
        shiftEvent = false;
      } else {
        eventPoints = parseInt(points);
      }
      if (shiftEvent === false) {
        numShifts = 0;
      }
      const eventData = {
        name: name,
        description: description,
        password: password,
        type: type,
        points: eventPoints,
        location: location,
        fromTime: fromTime,
        toTime: toTime,
        createdBy: props.auth.user.id,
        shiftsEvent: shiftEvent,
        numShifts: numShifts,
      };
      axios
        .post("/events/saveEvent", eventData)
        .then((res) => {
          if (res.status === 200) {
            setName("");
            setDescription("");
            setLocation("");
            setPassword("");
            setFromTime();
            setToTime();
            setShiftEvents(false);
            setNumShifts(1);
            setMessage({
              variant: "success",
              data: "Event has been successfully created!",
            });
            setTimeout(() => {
              setMessage(null);
            }, 6500);
          }
        })
        .catch((err) => {
          setError(err.response.data);
          if (err.response.data.invalidTimes) {
            setMessage({
              variant: "danger",
              data: err.response.data.invalidTimes,
            });
            setTimeout(() => {
              setMessage(null);
            }, 6500);
          }
        });
    }
  };

  return (
    <div className="Manager">
      <Container>
        <h2 className="display-2 text-center">Events Creation</h2>
        <div className="createEvent">
          {message && <Alert variant={message.variant}>{message.data}</Alert>}
          <Form noValidate onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>Name</b>
              </Form.Label>
              <Form.Control
                type="text"
                required
                id="name"
                placeholder="Name of event"
                onChange={(e) => setName(e.target.value)}
                value={name}
                tabIndex={1}
                isInvalid={!!error.name}
                className={classnames("", {
                  invalid: error.name,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {error.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <b>Description</b>
              </Form.Label>
              <Form.Control
                type="text"
                required
                id="description"
                placeholder="Description of event"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                tabIndex={1}
                isInvalid={!!error.description}
                className={classnames("", {
                  invalid: error.description,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {error.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <b>Location</b>
              </Form.Label>
              <Form.Control
                type="text"
                required
                id="location"
                placeholder="Location of event"
                onChange={(e) => setLocation(e.target.value)}
                value={location}
                tabIndex={1}
                isInvalid={!!error.location}
                className={classnames("", {
                  invalid: error.location,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {error.location}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <b>Password</b>
              </Form.Label>
              <Form.Control
                type="text"
                required
                id="password"
                placeholder="Password of event"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                tabIndex={1}
                isInvalid={!!error.password}
                className={classnames("", {
                  invalid: error.password,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {error.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="text-center">
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>From</b>
                  </Form.Label>
                  <Form.Text>
                    <DateTimePicker
                      className="datePicker shadow"
                      onChange={setFromTime}
                      value={fromTime}
                    />
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>To</b>
                  </Form.Label>
                  <Form.Text>
                    <DateTimePicker
                      className="datePicker shadow"
                      onChange={setToTime}
                      value={toTime}
                    />
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Type of event</b>
                  </Form.Label>
                  <Form.Select
                    id="eventType"
                    aria-label="Event type"
                    onChange={(e) => {
                      setType(e.target.value);
                      if (type === "Meeting") {
                        setShiftEvents(false);
                      }
                    }}
                  >
                    <option>Events</option>
                    <option>Career Fair</option>
                    <option>Engineering Development</option>
                    <option>Misc</option>
                    <option>Outreach</option>
                    <option>Social</option>
                    <option>Committee</option>
                    <option>Meeting</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                {type === "Meeting" ? (
                  <div></div>
                ) : (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Points for event</b>
                    </Form.Label>
                    <Form.Select
                      aria-label="Points"
                      onChange={(e) => setPoints(e.target.value)}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Form.Select>
                  </Form.Group>
                )}
              </Col>
            </Row>

            {type !== "Meeting" && (
              <Row>
                <Col>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      id="gridcheckbox"
                      label="Is this a shifts event?"
                      value={shiftEvent}
                      onChange={(e) => {
                        setShiftEvents((shiftEvent) => !shiftEvent);
                      }}
                    />
                  </Form.Group>
                </Col>
                {shiftEvent && (
                  <Col>
                    <Form.Label>
                      <b>Number of shifts:</b> {numShifts}
                    </Form.Label>
                    <Form.Range
                      onChange={(e) => {
                        setNumShifts(e.target.value);
                      }}
                      min={1}
                      max={10}
                      value={numShifts}
                    />
                  </Col>
                )}
              </Row>
            )}
            {editingEvent ? (
              <Button variant="primary" type="submit">
                Update Event
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Create event
              </Button>
            )}
          </Form>
        </div>
      </Container>
    </div>
  );
}

Manager.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Manager);
