import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Table } from "react-bootstrap";
import MyEvents from "../Events/MyEvents";

import axios from "axios";
import React from "react";

function Dashboard(props) {
  // State Management
  const [userPoints, setUserPoints] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [allMeetings, setAllMeetings] = useState([]);
  const isNm = props.auth.user.role === "nm";
  useEffect(() => {
    // Get user's points
    axios
      .get("/users/getCurrentUserPoints", {
        params: {
          userID: props.auth.user.id,
        },
      })
      .then((res) => {
        setUserPoints(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    // Get events user has attended
    axios
      .get("/events/getEventsForCurrentUser", {
        params: {
          userID: props.auth.user.id,
        },
      })
      .then((res) => {
        setUserEvents(res.data);
      })
      .catch((err) => console.log(err));

    // get a list of every meeting in the db
    axios
      .get("/events/getAllMeetings", {
        params: {
          userID: props.auth.user.id,
        },
      })
      .then((res) => {
        setAllMeetings(res.data);
      })
      .catch((err) => console.log(err));
  }, [props.auth.user.id]);

  // Sorting meetings and events
  const eventsForCurrentUser = [];
  const meetingsForCurrentUser = [];

  userEvents.forEach((userEvent) => {
    let eventCard = (
      <MyEvents
        name={userEvent.name}
        description={userEvent.description}
        points={userEvent.points}
        type={userEvent.type}
        key={userEvent._id}
      />
    );

    if (userEvent.type === "Meeting") {
      meetingsForCurrentUser.push(eventCard);
    } else {
      eventsForCurrentUser.push(eventCard);
    }
  });

  // Points Calculation
  const { points } = userPoints;
  let totalUserPoints = 0;
  let totalNeededPoints = 0;
  for (let p in points) {
    totalUserPoints += parseInt(points[p]);
  }
  let requiredPoints = {
    "Career Fair": 4,
    "Engineering Development": 1,
    Events: 3,
    Outreach: 2,
    Socials: 1,
    Committee: 2,
    Miscellaneous: 1,
  };

  for (let p in requiredPoints) {
    totalNeededPoints += parseInt(requiredPoints[p]);
  }
  totalNeededPoints -= requiredPoints["Miscellaneous"];

  return (
    <div className="MyPoints">
      <Container>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <React.Fragment>
            {!isNm ? (
              <React.Fragment>
                <h2 className="display-4 text-center">Points</h2>
                <Table striped bordered hover className="text-center">
                  <thead>
                    <tr>
                      <th>Points Category</th>
                      <th>Earned</th>
                      <th>Required</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(requiredPoints).map((points_category) => (
                      <tr key={points_category}>
                        <td>{points_category}</td>
                        <td>{points[points_category]}</td>
                        {requiredPoints[points_category] === 0 ? (
                          <td>-</td>
                        ) : (
                          <td>{requiredPoints[points_category]}</td>
                        )}
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>{totalUserPoints}</td>
                      <td>{totalNeededPoints}</td>
                    </tr>
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                  <p>
                    <i>
                      ** Miscellaneous points are overflow points from one other
                      category, capped at 1 point **
                    </i>
                  </p>
                </div>

                <h2 className="display-4 text-center">Meetings</h2>
                <Table striped bordered hover className="text-center">
                  <thead>
                    <tr>
                      <th>Attended</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{meetingsForCurrentUser.length}</td>
                      <td>{allMeetings.length}</td>
                    </tr>
                  </tbody>
                </Table>
                <h2 className="display-4 text-center">Events Attended</h2>
                <div className="card-columns">{eventsForCurrentUser}</div>
                <h2 className="display-4 text-center">Meetings Attended</h2>
                <div className="card-columns">{meetingsForCurrentUser}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <br></br>
                <h2 className="display-4 text-center">
                  Welcome to the SEC Membership Portal!
                </h2>
                <br></br>
                <p className="text-center">
                  To apply to join the SEC, go to the <i>'applications'</i> tab
                </p>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    </div>
  );
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
