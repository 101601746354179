import { Badge, Card, Button } from "react-bootstrap";
import React from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";

function MyReviews(props) {
  const history = useHistory();

  const editReview = (appID) => {
    axios.get("/applications/getApplicationById", {
      params:{
        appID: appID,
      }
    }).then((res) => {
        history.push("/reviewingApp/" + appID, {appData: res.data[0], result: false, editingApplication: true});
    })
    
  }

  return (
    <div className="MyReviews">
      <Card
        border="success"
        style={{ "borderWidth": "5px" }}
        className="p-3 m-4 rounded shadow"
      >
        <Card.Body>
          <Card.Title>
            Application: {props.tag}
            <Badge bg="success" className="m-2">
              {props.type}
            </Badge>
          </Card.Title>
          <p>Total Score: {props.score}</p>
          <p>Average Score: {props.average}</p>
          <Card.Text>{props.description}</Card.Text>
          {!props.closed &&
            <Button className="btn btn-success" onClick={() => editReview(props.appID)}>Edit Review</Button>
          }
        </Card.Body>
      </Card>
    </div>
  );
}

export default MyReviews;