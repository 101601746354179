import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Table, Modal, Nav, Navbar, Alert, Row, Col, Button, Input, Form} from "react-bootstrap";
import { useHistory, Route, Link, useParams} from "react-router-dom";
import classnames from "classnames";
import MyReviews from "../applications/MyReviews";
import axios from "axios";
import React from "react";
import "./appStyles.css";

function ReviewApplication(props) {
    // State Management
    const [isLoading, setLoading] = useState(true);
    const [isLoading2, setLoading2] = useState(true);
    const [isLoading3, setLoading3] = useState(true);
    const [isLoading4, setLoading4] = useState(true);
    const [applicationData, setApplicationData] = useState({});
    const [message, setMessage] = useState();
    const [error, setError] = useState({});
    const [showUpdateAccepted, setShowUpdateAccepted] = useState(false);
    const [showAddReviewer, setShowAddReviewer] = useState(false);
    const [numberAccepted, setNumberAccepted] = useState(0);

    const [showReviewsRequired, setShowReviewsRequired] = useState(false);
    const [reviewsRequired, setReviewsRequired] = useState(3);

    const [modalMessage, setModalMessage] = useState();
    const [modalMessage2, setModalMessage2] = useState();
    const [modalMessage3, setModalMessage3] = useState();
    const [reviewsNeeded, setReviewsNeeded] = useState(0);
    const [myReviewCards, setMyReviewCards] = useState([]);
    const [appResults, setAppResults] = useState([]);

    const [reviewerAdded, setReviewerAdded] = useState("");
    const [additionalReviewers, setAdditionalReviewers] = useState([]);
    const [potentialAdditionalReviewers, setPotentialAdditionalReviewers] = useState([]);

    const [showApplicantData, setShowApplicantData] = useState(false);
    const [applicantData, setApplicantData] = useState([]);

    const isAdmin = props.auth.user.role === "admin";

    const isSecretary = props.auth.user.id === "63d7d6817ad9f7cfa150966a";

    const history = useHistory();
    const appID = useParams().appID;

    useEffect(() => {
        if(history.location.state){
            if(history.location.state.message){
              setMessage({
                data: history.location.state.message,
                variant: history.location.state.variant,
              })
              setTimeout(() => {
                setMessage(null);
              }, 6500);
            }
          }
        axios.get("/applications/myReviews", {
            params:
            {appID: appID,
            userID: props.auth.user.id}
        }).then((res) => {
            setMyReviewCards(res.data);
        });
        axios.get("/applications/reviewsNeeded", {
            params:
            {appID}
        })
        .then((res) => {
            setReviewsNeeded(res.data.length);
        })
        axios.get("/users/getPotentialAdditionalReviewers", {
            params: {
                appID: appID,
            }
        }).then((res) => {
            setPotentialAdditionalReviewers(res.data);
            setLoading3(false);
        })
        axios.get("/applications/getApplicationById", {
            params:
              {
                userID: props.auth.user.id,
                appID: appID,
              }
          })
              .then((res) => {
                  setApplicationData(res.data[0]);
                  setAdditionalReviewers(res.data[0].additionalReviewers);

                  if(res.data[0].type == "closed"){
                    axios.get("/applications/getResults", {
                        params:{
                            appID: appID,
                        }
                    })
                    .then((res) => {
                        setAppResults(res.data);
                        setLoading2(false);
                    })
                  }else{
                    axios.get("/applications/getApplicantData", {
                        params:{
                            appID: appID,
                        }
                    })
                    .then((res) => {
                        setApplicantData(res.data);
                        setLoading4(false);
                    })
                  }

                  setLoading(false);
              })
              .catch((err) => console.log(err));
        
        
    }, []);

    const reviewResult = (appID) => {
        axios.get("/applications/reviewResult", {
            params:
            {
                appID: appID,
            }
        }).then((res) => {
            history.push("/reviewingapp/" + appID, {appData: res.data[0], result: true});
        });
    }

    const reviewApp = () => {
        axios.get("/applications/getAppToReview", {
            params:
            {
                parentID: appID,
                userID: props.auth.user.id,
            }
        }).then((res) => {
            if(res.data.length == 0){
                setMessage({
                    variant: "success",
                    data: "No applications for you to review!",
                  });
                  setTimeout(() => {
                    setMessage(null);
                  }, 6500);
                return;
            }
            const randomApplication = res.data[Math.floor(Math.random() * res.data.length)];
            history.push("/reviewingapp/" + randomApplication._id, {appData: randomApplication});
            
        });
    }

    const handleShowUpdateAccepted = () => {
        setNumberAccepted(applicationData.numberAccepted);
        setShowUpdateAccepted(true);
    }

    const closeUpdateAccepted = () => {
        setShowUpdateAccepted(false);
        setModalMessage(null);
    }

    const handleShowReviewsRequired = () => {
        setReviewsRequired(applicationData.reviewsPerApp);
        setShowReviewsRequired(true);
    }

    const closeReviewsRequired = () => {
        setShowReviewsRequired(false);
        setModalMessage2(null);
    }

    const handleShowAddReviewer = () => {
        setReviewerAdded("");
        setShowAddReviewer(true);
    }

    const closeAddReviewer = () => {
        setShowAddReviewer(false);
        setModalMessage(null);
    }

    const handleShowApplicantData = () => {
        setShowApplicantData(true);
    }

    const closeApplicantData = () => {
        setShowApplicantData(false);
    }

    function successEvent(res) {
        if (res.status === 200) {
          setMessage("Number Accepted successfully updated!");
        }
        setTimeout(() => {
          setMessage(null);
        }, 4000);
      }

    const saveReviewsRequired = () => {
        if(reviewsRequired < 1) {
            setModalMessage2({
                variant: "danger",
                data: "Number must 1 or greater!",
              });
              setTimeout(() => {
                setModalMessage2(null);
              }, 6500);
            return;
        }
        axios.post("/applications/updateReviewsRequired", {
            params: {
                appID: appID,
                reviewsRequired: reviewsRequired,
            }
        })
        .then((res) => {
            successEvent(res);
        })
        setShowReviewsRequired(false);
        setModalMessage2(null);
        window.location.reload();
    }

    const saveAddReviewer = () => {
        if(reviewerAdded.length <= 0 || !reviewerAdded.includes(" ")){
            setModalMessage3({
                variant: "danger",
                data: "Invalid user! User must include first and last name!",
              });
              setTimeout(() => {
                setModalMessage3(null);
              }, 6500);
            return;
        }

        let fname = reviewerAdded.split(" ")[0];
        let lname = reviewerAdded.split(" ")[1];

        axios.post("/applications/addAdditionalReviewer", {
            params: {
                appID: appID,
                fname: fname,
                lname: lname,
           }
        }).then((user) => {
            if(user.status == 200){
                const list = [...additionalReviewers, user.data.fname + " " + user.data.lname];
                setAdditionalReviewers(list)
                setShowAddReviewer(false);
                setModalMessage3(null);
            }else{
                setModalMessage3({
                    variant: "danger",
                    data: "Invalid user! User must include first and last name!",
                  });
                  setTimeout(() => {
                    setModalMessage3(null);
                  }, 6500);
                return;
            }
        })
        
    }

    const saveUpdateAccepted = () => {
        if(numberAccepted < 0){
            setModalMessage({
                variant: "danger",
                data: "Number must not be Negative!",
              });
              setTimeout(() => {
                setModalMessage(null);
              }, 6500);
            return;
        }
        axios.post("/applications/updateNumberAccepted", {
            params: {
                appID: appID,
                numberAccepted: numberAccepted,
            }
        })
        .then((res) => {
            successEvent(res);
        })
        setShowUpdateAccepted(false);
        setModalMessage(null);
        window.location.reload();
    }

    const closeApplication = () => {
        if(reviewsNeeded != 0){
            let ans = window.confirm("Are you sure you want to close this application? Not every application has been reviewed the same amount of times and applications cannot be reopened once closed.")
            if(!ans){
                return;
            }
        }else{
            let ans = window.confirm("Are you sure you want to close this application? Applications cannot be reopened once closed.")
            if(!ans){
                return;
            }
        }
        axios.post("/applications/closeApplication", {
            params: {
                appID: appID
            }
        })
        .then((res) => {
            window.location.reload();
        })
        .catch((err) => console.log(err));
        axios.post("/log/createLogItem", {
            params: {
              userID: props.auth.user.id,
              name: props.auth.user.fname + " " + props.auth.user.lname,
              type: "Closed",
              time: new Date(Date.now()),
              Description: `closed application: ${appID}.`,
            }
          })
    }

    const myReviews = [];
    // console.log(applicationData)
    if(!isLoading){
        myReviewCards.forEach((review) => {
            let index = review.reviewers.findIndex((e) => {return e.reviewedBy == props.auth.user.id})
            let sc = 0;
            for(let k = 0; k < review.reviewers[index].review.length; k++){
                sc += parseInt(review.reviewers[index].review[k].score);
            }
            let len = 0;
            for(let k = 0; k < applicationData.questions.length; k++){
                if(applicationData.questions[k].graded){
                    len++;
                }
            }
            let av = sc / len;
            let reviewCard = <MyReviews
                tag={review.tag}
                appID={review._id}
                name={review._id}
                score={sc}
                average={av}
                key={review._id}
                closed={applicationData.type == "closed"} 
                appData={applicationData} />
            myReviews.push(reviewCard);
        })
    }

    const mapResults = () => {
        const ans = [];
        for(let k = 0; k < appResults.length; k++){
            let temp =  <tr>
                            <td class="col-md-2">{k+1}</td>
                            <td class="col-md-2">
                                <Row>
                                    <div>
                                        {appResults[k].fname} {appResults[k].lname}
                                    </div>
                                </Row>
                                <Row>
                                    <div>
                                        {appResults[k].uin}
                                    </div>
                                </Row>
                                <Row>
                                    <div>
                                        {appResults[k].email}
                                    </div>
                                </Row>
                            </td>
                            <td class="col-md-2">{appResults[k].score}</td>
                            <td class="col-md-2"><Button variant="outline-primary" className="add-choice-btn" onClick={() => reviewResult(appResults[k]._id)}>
                                        {appResults[k].tag}
                                </Button></td>
                        </tr>
            ans.push(temp);
        }
        return ans;
    }

    const copyEmails = () => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        for(let k = 0; k < appResults.length; k++){
            if(k != appResults.length-1){
                dummy.value += appResults[k].email + ", ";
            }else{
                dummy.value += appResults[k].email;
            }
        }
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        
    }

    const copyApplicantEmails = () => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        for(let k = 0; k < applicantData.length; k++){
            if(k != applicantData.length-1){
                dummy.value += applicantData[k].email + ", ";
            }else{
                dummy.value += applicantData[k].email;
            }
        }
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        
    }

    const copyUINs = () => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        for(let k = 0; k < appResults.length; k++){
            if(k != appResults.length-1){
                dummy.value += appResults[k].uin + ", ";
            }else{
                dummy.value += appResults[k].uin;
            }
        }
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        
    }

    const copyApplicantUINs = () => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        for(let k = 0; k < applicantData.length; k++){
            if(k != applicantData.length-1){
                dummy.value += applicantData[k].uin + ", ";
            }else{
                dummy.value += applicantData[k].uin;
            }
        }
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        
    }

    const copyNames = () => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        for(let k = 0; k < appResults.length; k++){
            if(k != appResults.length-1){
                dummy.value += appResults[k].fname + " " + appResults[k].lname + ", ";
            }else{
                dummy.value += appResults[k].fname + " " + appResults[k].lname;
            }
        }
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        
    }

    const copyApplicantNames = () => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        for(let k = 0; k < applicantData.length; k++){
            if(k != applicantData.length-1){
                dummy.value += applicantData[k].fname + " " + applicantData[k].lname + ", ";
            }else{
                dummy.value += applicantData[k].fname + " " + applicantData[k].lname;
            }
        }
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        
    }

    
    const fillSuggestion = (suggestion) => {
        setReviewerAdded(suggestion)
    }

    const getAdditionalReviewerResults = (filter) => {
        const list = [];
        filter = filter.toLowerCase();
        for(let k = 0; k < potentialAdditionalReviewers.length; k++) {
            let user = potentialAdditionalReviewers[k];
            let userName = user.fname.toLowerCase() + " " + user.lname.toLowerCase();
            if(filter === ""){
                continue;
            }
            
            if(userName.includes(filter)){
                list.push(<div className="dropdown-row">
                            <a onClick={() => fillSuggestion(user.fname + " " + user.lname)}>
                            {user.fname} {user.lname}
                            </a>
                        </div>)
            }
        };
        return list;
    }

    const removeAdditionalReviewer = (index) => {
        const fname = additionalReviewers[index].split(" ")[0];
        const lname = additionalReviewers[index].split(" ")[1];

        axios.post("/applications/removeAdditionalReviewer", {
            params: {
                appID: appID, 
                fname: fname,
                lname: lname,
            }
        })

        const list = [...additionalReviewers];
        list.splice(index, 1);
        setAdditionalReviewers(list);
    }

    const getAdditionalReviewerTable = () => {
        const list = [];
        for(let k = 0; k < additionalReviewers.length; k++){
            let addMe = <tr>
                            <td>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col>
                                        <div>
                                        {additionalReviewers[k]}
                                        </div>
                                    </Col>
                                    <Col>
                                        {isAdmin &&
                                                <Button className="btn-danger" style={{float: "right"}} onClick={() => removeAdditionalReviewer(k)}>
                                                    - Remove Reviewer
                                                </Button>
                                        }
                                    </Col>
                                </Row>
                            </td>
                        </tr>
            list.push(addMe);
        }
        return list;
    }

    const applicantDataTable = () => {
        const list = []
        for( let k = 0; k < applicantData.length; k++){
            let temp =  <tr>
                            <td>{applicantData[k].name}</td>
                            <td>{applicantData[k].email}</td>
                            <td>{applicantData[k].uin}</td>
                        </tr>
            list.push(temp);
        }
        return list;
    }

    return (
        <div className="reviewApplication">
            <React.Fragment>
                <Container>
                    <React.Fragment>
                        <Modal show={showUpdateAccepted} onHide={closeUpdateAccepted}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Editing Number Accepted
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="container text-center d-inline">
                                {modalMessage && <Alert variant={modalMessage.variant}>{modalMessage.data}</Alert>}
                                    <p><i>Set to 0 to return all applications</i></p>
                                    <div className="row m-1">
                                       <Form>
                                       <Form.Group className="mb-3">
                                            <Form.Label>
                                            <b>Number Accepted</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                required
                                                id="numberAccepted"
                                                placeholder="Number"
                                                min="0"
                                                onChange={(e) => setNumberAccepted(e.target.value)}
                                                value={numberAccepted}
                                                tabIndex={1}
                                                isInvalid={error.name}
                                            />
                                        </Form.Group>
                                       </Form>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={closeUpdateAccepted}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={saveUpdateAccepted}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </React.Fragment>
                </Container>


                <Container>
                    <React.Fragment>
                        <Modal show={showReviewsRequired} onHide={closeReviewsRequired}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Editing Reviews Required
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="container text-center d-inline">
                                {modalMessage2 && <Alert variant={modalMessage2.variant}>{modalMessage2.data}</Alert>}
                                    <div className="row m-1">
                                       <Form>
                                       <Form.Group className="mb-3">
                                            <Form.Label>
                                            <b>Reviews Required</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                required
                                                id="reviewsRequired"
                                                placeholder="Number"
                                                min="1"
                                                onChange={(e) => setReviewsRequired(e.target.value)}
                                                value={reviewsRequired}
                                                tabIndex={1}
                                                isInvalid={error.name}
                                            />
                                        </Form.Group>
                                       </Form>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={closeReviewsRequired}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={saveReviewsRequired}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </React.Fragment>
                </Container>

                <Container>
                    <React.Fragment>
                        <Modal show={showAddReviewer} onHide={closeAddReviewer}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Add AdditionalReviewer
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="container text-center d-inline">
                                {modalMessage3 && <Alert variant={modalMessage3.variant}>{modalMessage3.data}</Alert>}
                                <p><i>First and Last name required!</i></p>
                                    <div className="row m-1">
                                       <Form>
                                       <Form.Group className="mb-3">
                                            <Form.Label>
                                            <b>Add Additional Reviewer</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                id="addAdditionalReviewer"
                                                placeholder="Name"
                                                onChange={(e) => setReviewerAdded(e.target.value)}
                                                value={reviewerAdded}
                                                tabIndex={1}
                                                isInvalid={error.name}
                                            />
                                        <div className="dropdown">
                                            {getAdditionalReviewerResults(reviewerAdded)}
                                        </div>
                                        </Form.Group>
                                       </Form>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={closeAddReviewer}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={saveAddReviewer}>
                                    Add!
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </React.Fragment>
                </Container>

                <Container>
                    <React.Fragment>
                        <Modal show={showApplicantData} onHide={closeApplicantData}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Applicant Data
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="container text-center d-inline">
                                    <Row className="text-center">
                                        <Col class="col-md-5">
                                            <Button variant="primary btn" onClick={() => copyApplicantNames()}>Copy Names</Button>
                                        </Col>
                                        <Col class="col-md-5">
                                            <Button variant="primary btn" onClick={() => copyApplicantEmails()}>Copy Emails</Button>
                                        </Col>
                                        <Col class="col-md-2">
                                            <Button variant="primary btn" onClick={() => copyApplicantUINs()}>Copy UINs</Button>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Table striped bordered hover className="text-center">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>UIN</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applicantDataTable()}
                                        </tbody>
                                    </Table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={closeApplicantData}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </React.Fragment>
                </Container>

                <Container>
                    {
                        isLoading || isLoading3
                            ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" />
                                </div>
                            )
                            : (
                                <React.Fragment>
                                    {applicationData.type == "closed" ? (
                                        <React.Fragment>
                                        {isLoading2 ? (
                                            <div className="d-flex justify-content-center">
                                                <Spinner animation="border" />
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                            <h2 className="display-2 text-center">{applicationData.name}: Closed!</h2>
                                        {message && <Alert variant={message.variant}>{message.data}</Alert>}
                                        <Table striped bordered hover className="text-center">
                                            <thead>
                                                <tr>
                                                    <th>Number of Submissions</th>
                                                    <th>Submissions Needing Review</th>
                                                    <th>Reviews Required per App</th>
                                                    <th># of Apps Accepted</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="col-md-2">{applicationData.responders.length}</td>
                                                    <td class="col-md-2">{reviewsNeeded}</td>
                                                    <td class="col-md-2">{applicationData.reviewsPerApp}</td>
                                                    <td class="col-md-2">
                                                        <Button class="btn-outline-secondary" onClick={() => handleShowUpdateAccepted()}>
                                                            {applicationData.numberAccepted}
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <br></br><br></br>
                                        <h2 className="display-3 text-center">Results!</h2>
                                        <Row className="text-center">
                                            <Col class="col-md-5">
                                                <Button variant="primary btn" onClick={() => copyEmails()}>Copy Emails</Button>
                                            </Col>
                                            <Col class="col-md-2">
                                                <Button variant="primary btn" onClick={() => copyUINs()}>Copy UINs</Button>
                                            </Col>
                                            <Col class="col-md-5">
                                                <Button variant="primary btn" onClick={() => copyNames()}>Copy Names</Button>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Table striped bordered hover className="text-center">
                                            <thead>
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>Submitted By</th>
                                                    <th>Average Score</th>
                                                    <th>View Application</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mapResults()}
                                                {/* <tr>
                                                    <td class="col-md-2">1</td>
                                                    <td class="col-md-2">Aidan</td>
                                                    <td class="col-md-2">10</td>
                                                    <td class="col-md-2">View</td>
                                                </tr> */}
                                            </tbody>
                                        </Table>
                                        <br></br><br></br>
                                        { myReviews.length != 0 &&
                                            <React.Fragment>
                                                <h2 className="display-3 text-center">My Reviews</h2>
                                                <h5 className="display-9 text-center">Total: {myReviewCards.length}</h5>
                                                <div className="card-columns">{myReviews}</div>
                                            </React.Fragment>
                                        }
                                            </React.Fragment>
                                        )}
                                        
                                        </React.Fragment>
                                    ) : (
                                    <React.Fragment>
                                        {isSecretary &&
                                            <React.Fragment>
                                            <br></br>
                                            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                                <Button variant="success btn-lg" onClick={() => handleShowApplicantData()}>
                                                    Get Applicant Data
                                                </Button>
                                            </div>
                                            </React.Fragment>
                                        }    
                                    <h2 className="display-2 text-center">Reviewing: {applicationData.name}</h2>
                                    {message && <Alert variant={message.variant}>{message.data}</Alert>}
                                    <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Number of Submissions</th>
                                            <th>Submissions Needing Review</th>
                                            <th>Reviews Required per App</th>
                                            <th># of Apps Accepted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="col-md-2">{applicationData.responders.length}</td>
                                            <td class="col-md-2">{reviewsNeeded}</td>
                                            <td class="col-md-2">
                                                <Button class="btn-outline-secondary" onClick={() => handleShowReviewsRequired()}>
                                                    {applicationData.reviewsPerApp}
                                                </Button>
                                            </td>
                                            <td class="col-md-2">
                                                <Button class="btn-outline-secondary" onClick={() => handleShowUpdateAccepted()}>
                                                    {applicationData.numberAccepted}
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <br></br>
                                <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Additional Reviewers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td>Aidan</td>
                                        </tr> */}
                                        {getAdditionalReviewerTable()}
                                        <tr>
                                            <td>
                                            <Button variant="primary btn" className="add-choice-btn" onClick={() => handleShowAddReviewer()}>
                                                + Add Reviewer
                                            </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <br></br><br></br>
                                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                    <Button variant="primary btn-lg" className="add-choice-btn" onClick={() => reviewApp()}>
                                        Review An Application!
                                    </Button>
                                </div>
            
                                <br></br><br></br>
                                { myReviews.length != 0 &&
                                    <React.Fragment>
                                        <h2 className="display-2 text-center">My Reviews</h2>
                                        <h5 className="display-9 text-center">Total: {myReviewCards.length}</h5>
                                        <div className="card-columns">{myReviews}</div>
                                    </React.Fragment>
                                }
                                <br></br>
                                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                    <Button variant="danger btn-lg" className="add-choice-btn" onClick={() => closeApplication()}>
                                        Close Application!
                                    </Button>
                                </div>
                                <br></br><br></br>
                                </React.Fragment>
                                    )
                                        
                                }
                                </React.Fragment>
                            )
                    }
                </Container>
            </React.Fragment>
        </div>
    );
}

ReviewApplication.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(ReviewApplication);
