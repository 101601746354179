import { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [banner, setBanner] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: email,
    };
    axios
      .post("/users/forgotPassword", { data })
      .then((res) => {
        setBanner({
          message: "Email successfully sent!",
          alertType: "success",
        });
        setEmail("");
        setTimeout(() => {
          setBanner({});
        }, 6500);
      })
      .catch((err) => {
        setError(err.response.data);
        if (err.response.data.userNotFound) {
          setBanner({
            message: err.response.data.userNotFound,
            alertType: "danger",
          });
        }
        setTimeout(() => {
          setBanner({});
        }, 6500);
      });
  };

  return (
    <div className="ForgotPassword">
      <Container>
        <h2 className="display-2 text-center">Forgot password</h2>
        {banner.message && (
          <Alert className="text-center" variant={banner.alertType}>
            {banner.message}
          </Alert>
        )}
        <Form noValidate onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Registered email address</b>
            </Form.Label>
            <Form.Control
              type="email"
              required
              id="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              tabIndex={1}
              isInvalid={!!error.email || !!error.emailnotfound}
              className={classnames("", {
                invalid: error.email || error.emailnotfound,
              })}
            />
            <Form.Control.Feedback type="invalid">
              {error.email}
              {error.emailnotfound}
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">
            Send Email
          </Button>
        </Form>
        <div className="text-center">
          <p className="fs-5">
            Want to sign in instead? <Link to="/login">Login here!</Link>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default ForgotPassword;
