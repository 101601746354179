import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Table, Modal, Nav, Navbar, Alert, Row, Col} from "react-bootstrap";
import MyEvents from "../Events/MyEvents";
import { useHistory, Route, Link} from "react-router-dom";

import axios from "axios";
import React from "react";
import "./appStyles.css";

function Applications(props) {
    // State Management
    const [isLoading, setLoading] = useState(true);
    const [isLoading2, setLoading2] = useState(true);
    const [isApplications, setIsApplications] = useState(false);
    const [openApps, setOpenApps] = useState([]);
    const [allApps, setAllApps] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [message, setMessage] = useState();
    const [error, setError] = useState({});
    const [launchedApps, setLaunchedApps] = useState([]);
    const [publishedApps, setPublishedApps] = useState([]);
    const [closedApps, setClosedApps] = useState([]);
    const [deprecatedApps, setDeprecatedApps] = useState([]);
    const [additionalApps, setAdditionalApps] = useState([]);
    const [isAdditionalReviewer, setIsAdditionalReviewer] = useState(false);
    const [usersSubmitted, setUsersSubmitted] = useState([]);
    const isNm = props.auth.user.role == "nm";

    const history = useHistory();

    const showApplication = (appID) => {

      history.push("/applications/"+appID, { from: appID });

    };

    const previewApplication = (appID) => {

      history.push("/applications/"+appID, { from: appID, preview: true });

    };

    const editApplication = (appID) => {
      history.push("/createapplication", {appID: appID});
    }

    const deleteApplication = (appID) => {
      let ans = window.confirm("Are you sure you want to delete this application? This action cannot be undone and will delete all responses.")
      if(!ans){
        return;
      }
      axios.delete("/applications/deleteApplication", {
        params:{
          appID: appID
        }
      })
      axios.post("/log/createLogItem", {
        params: {
          userID: props.auth.user.id,
          name: props.auth.user.fname + " " + props.auth.user.lname,
          type: "Deletion",
          time: new Date(Date.now()),
          Description: `deleted application: ${appID}.`,
        }
      })
      window.location.reload();
    }

    const reviewApplication = (appID) => {
        history.push("/reviewapplication/" + appID);
    }

    const unlaunchApplication = (appID) => {
        let ans = window.confirm("Are you sure you want to unlaunch application?");
        if(!ans){
          return;
        }
        axios.post("/applications/unlaunchApplication", {appID: appID})
          .then((res) => {
            if(res.status === 200){
              
              window.location.reload();
            }   
          })
          .catch((err) => {
            setError(err.response.data);
              setMessage({
                variant: "danger",
                data: err.response.data,
              });
              setTimeout(() => {
                setMessage(null);
              }, 6500);
        })

        axios.post("/log/createLogItem", {
          params: {
            userID: props.auth.user.id,
            name: props.auth.user.fname + " " + props.auth.user.lname,
            type: "Unlaunched",
            time: new Date(Date.now()),
            Description: `unlaunched application: ${appID}.`,
          }
        })
    }

    const launchApplication = (appID) => {
      let ans = window.confirm("Are you sure you want to launch application?");
      if(!ans){
        return;
      }
      axios.post("/applications/launchApplication", {appID: appID})
      .then((res) => {
        if(res.status === 200){
          window.location.reload();
        }   
      })
      .catch((err) => {
        setError(err.response.data);
          setMessage({
            variant: "danger",
            data: err.response.data,
          });
          setTimeout(() => {
            setMessage(null);
          }, 6500);
      })
      axios.post("/log/createLogItem", {
        params: {
          userID: props.auth.user.id,
          name: props.auth.user.fname + " " + props.auth.user.lname,
          type: "Launched",
          time: new Date(Date.now()),
          Description: `launched application: ${appID}.`,
        }
      })
    }

    const createApplication = () => {
      history.push("/createapplication");
    };

    useEffect(() => {
      if(history.location.state){
        if(history.location.state.message){
          setMessage({
            data: history.location.state.message,
            variant: history.location.state.variant,
          })
          setTimeout(() => {
            setMessage(null);
          }, 6500);
        }
      }
      axios.get("/users/getUserPoints", {
        params:{
          userID: props.auth.user.id,
        },
      })
        .then((res) => {
          setIsAdmin(res.data[0].role == "admin");
          
        })

      axios.get("/users/getAdditionalReviews", {
        params: {
          userID: props.auth.user.id,
        },
      })
      .then((res) => {
        setAdditionalApps(res.data);
        setIsAdditionalReviewer(res.data.length > 0);
      })
        
      axios.get("/applications/getApplicationModels", {
        params: {
          userID: props.auth.user.id,
          userRole: props.auth.user.role,
        }
      })
          .then((res) => {
              if(res.data.length != 0){
                setIsApplications(true);
              }
              setOpenApps(res.data);
              //console.log(res.data);
          })
          .catch((err) => console.log(err));

      axios.get("/applications/getAllApplicationModels")
          .then((res) => {
              setAllApps(res.data);
              getLaunchedApps(res.data);
              // console.log(res.data);
              setLoading(false);
          })
          .catch((err) => console.log(err));

      axios.get("/applications/getUsersSubmitted", {
        params:{
          userID: props.auth.user.id
        }
      })
      .then((res) => {
        setUsersSubmitted(res.data)
      })

    }, []);

    const getLaunchedApps = (d) => {
      const list = [];
      const list2 = [];
      const list3 = [];
      const list4 = [];
      d.forEach((app) => {
        if(app.type == "closed"){
          list3.push(app);
        }
        else if(app.type == "deprecated"){
          list4.push(app);
        }
        else if(app.isLaunched){
          list.push(app);
        } else{
          list2.push(app);
        }

      })

      setLaunchedApps(list);
      setPublishedApps(list2);
      setClosedApps(list3);
      setDeprecatedApps(list4)
    }

    let open_apps = openApps;

    if(isAdmin){
      open_apps = allApps;
    }

    
    return (
        <div className="MyPoints">
            <Container>
                {
                    isLoading
                        ? (
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" />
                            </div>
                        )
                        : (
                            <React.Fragment>
                              {message && <React.Fragment><br></br><Alert variant={message.variant}>{message.data}</Alert></React.Fragment>}
                              {isAdmin &&
                                <h2 className="display-4 text-center">
                                  <button type="button" className="btn btn-primary" onClick={() => createApplication()}>
                                    + Create Application
                                  </button>
                                </h2>
                              
                              }

                              { !isApplications && !isAdmin &&
                                  <h2 className="display-4 text-center">
                                    No Applications Available!
                                  </h2>
                              }
                              
                              { isApplications && !isAdmin && 
                                <React.Fragment>
                                    <h2 className="display-4 text-center">
                                    Open Applications
                                </h2>
                                <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Application Name</th>
                                            <th>View Application</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            Object.keys(open_apps).map((app) => (
                                              <React.Fragment>
                                                <tr key={app}>
                                                    <td class="col-md-5">{open_apps[app].name}</td>
                                                    <td class="col-md-3">
                                                      <Nav.Link className="btn btn-primary" onClick={() => showApplication(open_apps[app]._id)}> Open
                                                      </Nav.Link>
                                                    </td>
                                                    
                                                </tr>
                                              
                                              </React.Fragment>
                                            ))
                                        }
                                    </tbody>
                                </Table>                                
                                </React.Fragment>
                              }

                              {usersSubmitted.length > 0 && !isAdmin && 
                                  <React.Fragment>
                                    <br></br>
                                    <hr></hr>
                                    <br></br>
                                    <h2 className="display-4 text-center">
                                        Submitted Applications
                                    </h2>
                                    <Table striped bordered hover className="text-center">
                                        <thead>
                                            <tr>
                                                <th>Application Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { 
                                                Object.keys(usersSubmitted).map((app) => (
                                                  <React.Fragment>
                                                    <tr key={app}>
                                                        <td class="col-md-5">{usersSubmitted[app].name}</td>
                                                    </tr>
                                                  
                                                  </React.Fragment>
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                  </React.Fragment>
                                }
                              
                              {isAdditionalReviewer &&
                                  <React.Fragment>
                                    <br></br>
                                    <hr></hr>
                                    <br></br>
                                    <h2 className="display-4 text-center">
                                        Additional Reviewer
                                    </h2>

                                    <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Application Name</th>
                                            <th>Actions</th>
                                            <th>Demographic</th>
                                            <th># Submitted</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            Object.keys(additionalApps).map((app) => (
                                                <tr key={app}>
                                                    <td class="col-md-4">{additionalApps[app].name}</td>
                                                    <td class="col-md-5">
                                                    <Row>
                                                        <Col>
                                                          <Nav.Link className="btn btn-secondary" onClick={() => previewApplication(additionalApps[app]._id)}> Preview
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-primary" onClick={() => reviewApplication(additionalApps[app]._id)}> Review
                                                          </Nav.Link>
                                                        </Col>
                                                      </Row>
                                                    </td>
                                                    <td class="col-md-1">{additionalApps[app].demographic}</td>
                                                    <td class="col-md-1">
                                                        <p>{additionalApps[app].responders.length}</p>
                                                    </td>
                                                    <td class="col-md-1">
                                                      {additionalApps[app].type == "model" ? (
                                                        <React.Fragment>
                                                        {additionalApps[app].isLaunched ? (
                                                            <p>Launched</p>
                                                          ) : (
                                                            <p>Published</p>
                                                          )
                                                        }
                                                        </React.Fragment>
                                                      ) : (
                                                        <p>closed</p>
                                                      )}
                                                        
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                </React.Fragment>
                                }

                              { isAdmin &&
                                <React.Fragment>
                                    <h2 className="display-4 text-center">
                                      Launched Applications
                                    </h2>
                                <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Application Name</th>
                                            <th>Actions</th>
                                            <th>Demographic</th>
                                            <th># Submitted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            Object.keys(launchedApps).map((app) => (
                                                <tr key={app}>
                                                    <td class="col-md-4">{launchedApps[app].name}</td>
                                                    <td class="col-md-5">
                                                    <Row>
                                                        <Col>
                                                          <Nav.Link className="btn btn-secondary" onClick={() => previewApplication(launchedApps[app]._id)}> Preview
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-primary" onClick={() => reviewApplication(launchedApps[app]._id)}> Review
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-danger" onClick={() => unlaunchApplication(launchedApps[app]._id)}> Unlaunch
                                                          </Nav.Link>
                                                        </Col>
                                                      </Row>
                                                    </td>
                                                    <td class="col-md-1">{launchedApps[app].demographic}</td>
                                                    <td class="col-md-1">
                                                        <p>{launchedApps[app].responders.length}</p>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <br></br>
                                <h2 className="display-4 text-center">
                                      Published Applications
                                    </h2>
                                <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Application Name</th>
                                            <th>Actions</th>
                                            <th>Demographic</th>
                                            <th># Submitted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            Object.keys(publishedApps).map((app) => (
                                                <tr key={app}>
                                                    <td class="col-md-4">{publishedApps[app].name}</td>
                                                    
                                                    <td class="col-md-5">
                                                      <Row>
                                                        <Col>
                                                          <Nav.Link className="btn btn-secondary" onClick={() => previewApplication(publishedApps[app]._id)}> Preview
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-warning" onClick={() => editApplication(publishedApps[app]._id)}> Edit
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-primary" onClick={() => reviewApplication(publishedApps[app]._id)}> Review
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-success" onClick={() => launchApplication(publishedApps[app]._id)}> Launch
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-danger" onClick={() => deleteApplication(publishedApps[app]._id)}> Delete
                                                          </Nav.Link>
                                                        </Col>
                                                      </Row>
                                                    </td>
                                                    <td class="col-md-1">{publishedApps[app].demographic}</td>
                                                    <td class="col-md-1">
                                                        <p>{publishedApps[app].responders.length}</p>
                                                    </td>
                                                    
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <br></br>
                                <h2 className="display-4 text-center">
                                      Closed Applications
                                    </h2>
                                <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Application Name</th>
                                            <th>Actions</th>
                                            <th>Demographic</th>
                                            <th># Submitted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            Object.keys(closedApps).map((app) => (
                                                <tr key={app}>
                                                    <td class="col-md-4">{closedApps[app].name}</td>
                                                    
                                                    <td class="col-md-5">
                                                      <Row>
                                                        <Col>
                                                          <Nav.Link className="btn btn-secondary" onClick={() => previewApplication(closedApps[app]._id)}> Preview
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-primary" onClick={() => reviewApplication(closedApps[app]._id)}> Review Results
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-danger" onClick={() => deleteApplication(closedApps[app]._id)}> Delete
                                                          </Nav.Link>
                                                        </Col>
                                                      </Row>
                                                    </td>
                                                    <td class="col-md-1">{closedApps[app].demographic}</td>
                                                    <td class="col-md-1">
                                                        <p>{closedApps[app].responders.length}</p>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>

                                <br></br>
                                <h2 className="display-4 text-center">
                                      Deprecated Applications
                                    </h2>
                                <Table striped bordered hover className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Application Name</th>
                                            <th>Actions</th>
                                            <th>Demographic</th>
                                            <th># Submitted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            Object.keys(deprecatedApps).map((app) => (
                                                <tr key={app}>
                                                    <td class="col-md-4">{deprecatedApps[app].name}</td>
                                                    
                                                    <td class="col-md-5">
                                                      <Row>
                                                        <Col>
                                                          <Nav.Link className="btn btn-secondary" onClick={() => previewApplication(deprecatedApps[app]._id)}> Preview
                                                          </Nav.Link>
                                                        </Col>
                                                        <Col>
                                                          <Nav.Link className="btn btn-danger" onClick={() => deleteApplication(deprecatedApps[app]._id)}> Delete
                                                          </Nav.Link>
                                                        </Col>
                                                      </Row>
                                                    </td>
                                                    <td class="col-md-1">{deprecatedApps[app].demographic}</td>
                                                    <td class="col-md-1">
                                                        <p>{deprecatedApps[app].responders.length}</p>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>

                                </React.Fragment>
                              }
                              <br></br><br></br>
                            </React.Fragment>
                        )
                }
            </Container>
        </div>
    );
}

Applications.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Applications);
