import { Badge, Card } from "react-bootstrap";
import React from "react";

let badgeIcon = {
  Events: "warning",
  "Career Fair": "secondary",
  "Engineering Development": "danger",
  Misc: "primary",
  Outreach: "info",
  Social: "success",
  Meeting: "dark",
};

function MyEvents(props) {
  return (
    <div className="MyEvents">
      <Card
        border={badgeIcon[props.type]}
        style={{ borderWidth: "5px" }}
        className="p-3 m-4 rounded shadow"
      >
        <Card.Body>
          <Card.Title>
            {props.name}
            <Badge bg={badgeIcon[props.type]} className="m-2">
              {props.type}
            </Badge>
          </Card.Title>
          <p>Type: {props.type}</p>
          <p>
            {props.type === "Meeting" ? (
              <React.Fragment></React.Fragment>
            ) : (
              <React.Fragment>
                {" "}
                <b>Points :</b> {props.points}{" "}
              </React.Fragment>
            )}
          </p>
          <p>
            <b>Location:</b> {props.location}
          </p>
          <Card.Text>{props.description}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MyEvents;
