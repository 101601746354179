import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import classnames from "classnames";
import axios from "axios";

import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';


// import Editor from "./ckeditor";
import "./appStyles.css";

function CreateApplication(props) {
  const history = useHistory();

  const [error, setError] = useState({});
  const [appName, setAppName] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState();
  const [questions, setQuestions] = useState([{type: "short-answer", q_prompt: "", minWords: 0, maxWords: 0, choices: [{option:""}], graded: true }]);
  const [demographic, setDemographic] = useState("members");
  const [currentResponseCount, setCurrentResponseCount] = useState(0)

  const editData = history.location.state;

    useEffect(() => {
        //console.log(props.errors);
        if(editData){
            axios.get("/applications/getApplicationById", {
                params: {
                    appID: editData.appID
                }
            })
            .then((res) => {
                fillData(res.data[0]);
            })
        }
        setError(props.errors);
    }, [props.errors]);

    const fillData = (data) => {
        setAppName(data.name);
        setDescription(data.description);
        const questionList = [];
        for(let k = 0; k < data.questions.length; k++){
            questionList.push({type: data.questions[k].type, q_prompt: data.questions[k].q_prompt, choices: data.questions[k].choices, graded: data.questions[k].graded, minWords: data.questions[k].minWords, maxWords: data.questions[k].maxWords});
        }
        setQuestions(questionList);
        setCurrentResponseCount(data.responders.length)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const appData = {
            appName: appName,
            createdBy: props.auth.user.id,
            questions: questions,
            description: description,
            demographic: demographic,
        };
        if(editData){
            let ans = window.confirm("Are you sure you would like to update this application? If any responses have been recorded, they will be deleted.");
            if(!ans){
                return;
            }
            axios.post("/applications/updateApplication", {
                params:{
                    appData: appData,
                    appID: editData.appID,
                }
            })
            .then((res) => {
                if(res.status === 200){
                    history.push("/applications", {message: "Application Updated Successfully!", variant: "success"})
                }         
            })
            .catch((err) => {
                setError(err.response.data);
                if (err.response.data.appName) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.appName,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
                else if (err.response.data.question) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.question,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
                else if (err.response.data.choices) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.choices,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
                else if (err.response.data.wordCount) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.wordCount,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
            })
            axios.post("/log/createLogItem", {
                params: {
                  userID: props.auth.user.id,
                  name: props.auth.user.fname + " " + props.auth.user.lname,
                  type: "Edited",
                  time: new Date(Date.now()),
                  Description: `edited application: ${appName}.`,
                }
              })
        } 
        else {
            axios.post("/applications/createApplication", appData)
            .then((res) => {
                if(res.status === 200){
                    history.push("/applications", {message: "Application Created Successfully!", variant: "success"})
                }         
            })
            .catch((err) => {
                setError(err.response.data);
                if (err.response.data.appName) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.appName,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
                else if (err.response.data.question) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.question,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
                else if (err.response.data.choices) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.choices,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
                else if (err.response.data.wordCount) {
                    setMessage({
                        variant: "danger",
                        data: err.response.data.wordCount,
                    });
                    setTimeout(() => {
                        setMessage(null);
                    }, 6500);
                }
            })
            axios.post("/log/createLogItem", {
                params: {
                  userID: props.auth.user.id,
                  name: props.auth.user.fname + " " + props.auth.user.lname,
                  type: "Creation",
                  time: new Date(Date.now()),
                  Description: `created application: ${appName}.`,
                }
              })
        }
        
    };

    let count = 1;

    const addQuestion = () => {
        setQuestions([...questions, {
            type: "short-answer",
            q_prompt: "",
            minWords: 0, 
            maxWords: 0,
            choices: [{option: ""}],
            graded: true,
        }])
    }

    const removeQuestion = (i) => {
        const list = [...questions];
        list.splice(i, 1);
        setQuestions(list);
    }

    const setQuestionPrompt = (e, i) => {
        const {name, value} = e.target;
        const list = [...questions];
        list[i].q_prompt = value;
        setQuestions(list);
    }

    const setQuestionType = (e, i) => {
        const {name, value} = e.target;
        const list = [...questions];
        list[i].type = value;
        list[i].choices = [{option: ""}];
        setQuestions(list);
    }

    const setChoice = (e, index, k) => {
        const {name, value} = e.target;
        const list = [...questions];
        const list2 = [...questions[index].choices];
        list2[k].option = value;
        list[index].choices = list2;
        setQuestions(list);
    }

    const addChoice = (index) => {
        const list = [...questions];
        const list2 = [...questions[index].choices, {
            option: "",
        }];
        list[index].choices = list2;
        setQuestions(list);
    }

    const removeChoice = (index, k) => {
        const list = [...questions];
        const list2 = [...questions[index].choices];
        list2.splice(k, 1);
        list[index].choices = list2;
        setQuestions(list);
    }

    const mapChoices = (index) => {
            // console.log(questions[index].choices);
            // console.log(questions[index].choices[0]);
            //console.log(questions[index].choices[i]);
            
            let choiceStack = [];
            for(let k = 0; k < questions[index].choices.length; k++){
                let ans = <React.Fragment>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col>
                                    <div style={{float: 'right'}}>
                                        <Form.Label>
                                            <b>Option {k+1}</b>
                                        </Form.Label>
                                    </div>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            required
                                            id="option"
                                            placeholder={"Option " + (k+1) }
                                            onChange={(e) => setChoice(e, index, k)}
                                            value={questions[index].choices[k].option}
                                            tabIndex={1}
                                            isInvalid={error.name}
                                            className={classnames("", {
                                                invalid: error.name,
                                            })}
                                        />
                                    </Col>
                                    <Col>
                                        {questions[index].choices.length != 1 &&
                                            <Button variant="danger" className="remove-btn" onClick={() => removeChoice(index, k)}>
                                                - Remove Choice
                                            </Button>
                                        }
                                    </Col>
                                    <Form.Control.Feedback type="invalid">
                                        {error.name}
                                    </Form.Control.Feedback>
                                </Row>
                            </Form.Group>

                        </React.Fragment>
                choiceStack.push(ans);
            }
            
        
        return <div>{choiceStack}</div>;
    }

    const setGraded = (e, i) => {
        const ans = !e.target.checked;
        const list = [...questions];
        list[i].graded = ans;
        setQuestions(list);
    }

    const setMinWords = (e, i) => {
        const list = [...questions];
        list[i].minWords = e.target.value
        setQuestions(list)
    }

    const setMaxWords = (e, i) => {
        const list = [...questions];
        list[i].maxWords = e.target.value
        setQuestions(list)
    }
    
    return (
        <div className="CreateApplication">
        <Container>
            {message && <Alert variant={message.variant}>{message.data}</Alert>}
            <h2 className="display-2 text-center">Create Application</h2>
            <Form.Group className="mb-2">
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <Row>
                        <Col class="col-md-5">
                            <Form.Label>
                                <b>Demographic:</b>
                            </Form.Label>
                        </Col>
                        <Col class="col-md-7">                                
                            <Form.Group className="mb-2" class="col-mb-6">
                                <Form.Select
                                    aria-label="Event type"
                                    value={demographic}
                                    onChange={(e) => {
                                    setDemographic(e.target.value);
                                    }}
                                >
                                <option>members</option>
                                <option>non-members</option>
                                <option>everyone</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                                   
            </Form.Group>
            
            
            <Form noValidate onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>
                    <b>Application Name</b>
                    </Form.Label>
                    <Form.Control
                    type="text"
                    required
                    id="appName"
                    placeholder="Application Name"
                    onChange={(e) => setAppName(e.target.value)}
                    value={appName}
                    tabIndex={1}
                    isInvalid={error.name}
                    className={classnames("", {
                        invalid: error.name,
                    })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error.name}
                    </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                    <Form.Label>
                    <b>Description</b>
                    </Form.Label>
                    <Form.Control
                    as="textarea"
                    rows="3"
                    required
                    id="desc"
                    placeholder="Application Name"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    tabIndex={1}
                    isInvalid={error.name}
                    className={classnames("", {
                        invalid: error.name,
                    })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error.name}
                    </Form.Control.Feedback>
                </Form.Group> */}

                <Form.Label><b>Description</b></Form.Label>
                {/* <CKEditor
                    id="editor"
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        setDescription(data)
                    }}
                /> */}
                {/* <CKEditor
                    id="editor"
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        setDescription(data)
                    }}
                    editor={ DecoupledEditor }
                    data={description}
                /> */}
                <div class="document-editor">
                    <div class="document-editor__toolbar"></div>
                    <div class="document-editor__editable-container">
                        <div class="document-editor__editable">
                            <CKEditor
                                onReady={(editor) => {
                                    console.log("Editor is ready to use!", editor);

                                    // Insert the toolbar before the editable area.
                                    editor.ui
                                    .getEditableElement()
                                    .parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );

                                    // this.editor = editor;
                                }}
                                onError={(error, { willEditorRestart }) => {
                                    // If the editor is restarted, the toolbar element will be created once again.
                                    // The `onReady` callback will be called again and the new toolbar will be added.
                                    // This is why you need to remove the older toolbar.
                                    if (willEditorRestart) {
                                    this.editor.ui.view.toolbar.element.remove();
                                    }
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    setDescription(data)
                                }}
                                editor={DecoupledEditor}
                                data={description}
                                />
                        </div>
                    </div>
                </div>
                
                

                {Object.keys(questions).map((q, index) => 
                    <React.Fragment>
                        {questions[index].type == "short-answer" || questions[index].type == "" ? (
                            <React.Fragment>
                                <Form.Group key={index} className="mb-3">
                                    <Form.Label>
                                    <br></br>
                                    <b>Question {index+1}</b>
                                    </Form.Label>
                                    <Row>
                                    <Col className="col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Select
                                            aria-label="Event type"
                                            value={questions[index].type}
                                            onChange={(e) => {
                                                setQuestionType(e, index);
                                            }}
                                        >
                                        <option>short-answer</option>
                                        <option>multiple-choice</option>
                                        <option>check-boxes</option>
                                        </Form.Select>
                                    </Form.Group>
                                    </Col>

                                    {questions.length != 1 &&
                                        <Col className="col-md-2">
                                            <Button variant="danger" className="remove-btn" onClick={() => removeQuestion(index)}>
                                                - Remove Question
                                            </Button>
                                        </Col>
                                    }

                                    <Col className="col-md-2">
                                        <Form.Check
                                        type="checkbox"
                                        label="Do not grade this question"
                                        checked={!questions[index].graded}
                                        onChange={(e) => {
                                          setGraded(e, index);
                                        }}
                                        />
                                    </Col>

                                    <Col className="col-md-3">
                                        <Row>
                                            <Col className="col-md-3">
                                                <Form.Label>
                                                    Min Words:
                                                </Form.Label>
                                            </Col>
                                            <Col className="col-md-4">
                                                <Form.Control
                                                type="number"
                                                min="0"
                                                placeholder="0, 200, ..."
                                                onChange={(e) => setMinWords(e, index)}
                                                value={questions[index].minWords}
                                                tabIndex={1}
                                                isInvalid={error.name}
                                                className={classnames("", {
                                                    invalid: error.name,
                                                })}
                                            />
                                            </Col>
                                        </Row>
                                        
                                    </Col>

                                    <Col className="col-md-3">
                                        <Row>
                                            <Col className="col-md-3">
                                                <Form.Label>
                                                    Max Words:
                                                </Form.Label>
                                            </Col>
                                            <Col className="col-md-4">
                                                <Form.Control
                                                type="number"
                                                min="0"
                                                placeholder="0, 200, ..."
                                                onChange={(e) => setMaxWords(e, index)}
                                                value={questions[index].maxWords}
                                                tabIndex={1}
                                                isInvalid={error.name}
                                                className={classnames("", {
                                                    invalid: error.name,
                                                })}
                                            />
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                    
                                    </Row>
                                    
                                    <Form.Control
                                        type="text"
                                        required
                                        id={index}
                                        placeholder="Prompt"
                                        onChange={(e) => setQuestionPrompt(e, index)}
                                        value={questions[index].q_prompt}
                                        tabIndex={1}
                                        isInvalid={error.name}
                                        className={classnames("", {
                                            invalid: error.name,
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                            </React.Fragment>

                        ) : (
                            <React.Fragment>
                                <Form.Group key={index} className="mb-3">
                                    <Form.Label>
                                    <br></br>
                                    <b>Question {index+1}</b>
                                    </Form.Label>
                                    <Row>
                                    <Col className="col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Select
                                            aria-label="Event type"
                                            value={questions[index].type}
                                            onChange={(e) => {
                                                setQuestionType(e, index);
                                            }}
                                        >
                                        <option>short-answer</option>
                                        <option>multiple-choice</option>
                                        <option>check-boxes</option>
                                        </Form.Select>
                                    </Form.Group>
                                    </Col>
                                    {questions.length != 1 &&
                                        <Col className="col-md-2">
                                            <Button variant="danger" className="remove-btn" onClick={() => removeQuestion(index)}>
                                                - Remove Question
                                            </Button>
                                        </Col>
                                    }

                                    <Col className="col-md-3">
                                        <Form.Check
                                        type="checkbox"
                                        label="Do not grade this question"
                                        checked={!questions[index].graded}
                                        onChange={(e) => {
                                          setGraded(e, index);
                                        }}
                                        />
                                    </Col>

                                    </Row>
                                    
                                    <Form.Control
                                        type="text"
                                        required
                                        id={index}
                                        placeholder="Prompt"
                                        onChange={(e) => setQuestionPrompt(e, index)}
                                        value={questions[index].q_prompt}
                                        tabIndex={1}
                                        isInvalid={error.name}
                                        className={classnames("", {
                                            invalid: error.name,
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                {mapChoices(index)}
                                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Button variant="primary" className="add-choice-btn" onClick={() => addChoice(index)}>
                                + Add Choice
                            </Button>
                            </div>
                            </React.Fragment>
                        )}
                        
                    </React.Fragment>

                )}
                <div>
                <br></br>
                <Button variant="primary" className="add-btn" onClick={() => addQuestion()}>
                    + Add Question
                </Button>

                <br></br><br></br><br></br>
                <hr></hr>
                <h2 className="display-2 text-center">Grading Criteria</h2>
                <p className="text-center">Grading Criteria is applied to each question individually. An overall score for each criteria is calulated at the end automatically.</p>


                {!editData ? (
                    <div style={{float: 'right'}}>
                    <Button variant="primary" className="submit-btn" type="submit">
                        Create Application
                    </Button>
                </div>
                ) : (
                    <React.Fragment>
                        <div class="alert alert-danger" role="alert">
                            <h2 className="display-10 text-center">WARNING: Updating an application will delete every application that has already been submitted for this application!</h2>
                            <p className="text-center">Currently there are <b>{currentResponseCount}</b> application(s) that would get deleted.</p>
                        </div>
                        <div style={{float: 'right'}}>
                            <Button variant="primary" className="submit-btn" type="submit">
                                Update Application
                            </Button>
                        </div>
                    </React.Fragment>
                )
                    
                }
                
                </div>
            </Form>
            <br></br><br></br>
        </Container>
        </div>
    );
    }

    CreateApplication.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    };

    const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    });

    export default connect(mapStateToProps, { loginUser })(CreateApplication);