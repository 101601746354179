//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import PrivateRoute from "./components/private-route/PrivateRoute";
import PrivateManagerRoute from "./components/private-route/PrivateManagerRoute";
import PrivateAdminRoute from "./components/private-route/PrivateAdminRoute";
import PrivateReviewRoute from "./components/private-route/PrivateReviewRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Events from "./components/Events/Events";
import Meetings from "./components/Events/Meetings";
import Directory from "./components/member_directory/Directory";
import Manager from "./components/manager/manager";
import Landing from "./components/layout/Landing";
import NavBar from "./components/layout/NavBar";
import Admin from "./components/admin/admin";
import Applications from "./components/applications/applications";
import UserApplication from "./components/applications/userapplication";
import CreateApplication from "./components/applications/createapplication";
import ReviewApplication from "./components/applications/reviewApplication";
import ReviewingApp from "./components/applications/reviewingApp";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import jwt_decode from "jwt-decode";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds

  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <NavBar />
          <Route exact path="/" component={Landing} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route
            exact
            path="/resetpassword/:resetToken"
            component={ResetPassword}
          />
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/events" component={Events} />
            <PrivateRoute exact path="/meetings" component={Meetings} />
            <PrivateRoute exact path="/directory" component={Directory} />
            <PrivateManagerRoute exact path="/manager" component={Manager} />
            <PrivateRoute exact path="/applications" component={Applications} />
            <PrivateRoute
              exact
              path="/applications/:appID"
              component={UserApplication}
            />
            <PrivateAdminRoute exact path="/admin" component={Admin} />
            <PrivateAdminRoute
              exact
              path="/createapplication"
              component={CreateApplication}
            />
            <PrivateReviewRoute
              exact
              path="/reviewapplication/:appID"
              component={ReviewApplication}
            />
            <PrivateReviewRoute
              exact
              path="/reviewingApp/:appID"
              component={ReviewingApp}
            />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
