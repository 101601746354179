import {
  Container,
  Card,
  Form,
  Button,
  Badge,
  Row,
  Col,
} from "react-bootstrap";
import { useState, React } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

let badgeIcon = {
  Events: "warning",
  "Career Fair": "secondary",
  "Engineering Development": "danger",
  Misc: "primary",
  Outreach: "info",
  Social: "success",
  Meeting: "dark",
  Committee: "primary",
};

function EventCards(props) {
  const [password, setPassword] = useState("");
  let [numShifts, setNumShifts] = useState(1);

  const history = useHistory();

  function successEvent(res, eventID) {
    if (res.status === 200) {
      props.removeEventReRender(eventID, true);
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let userID = props.auth.user.id;
    let eventID = props.eventKey;
    axios
      .post("/events/validateUserToEvent", {
        data: {
          userID: userID,
          eventID: eventID,
          password: password,
          numShifts: numShifts,
        },
      })
      .then((res) => {
        successEvent(res, eventID);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          props.removeEventReRender(eventID, false);
        }
      });
  };


  let fromDate = new Date(props.from_time);
  let toDate = new Date(props.to_time);
  fromDate = fromDate.toLocaleString();
  toDate = toDate.toLocaleString();
  
  const editEvent = () => {
    const eventData = {
      name: props.name,
      description: props.description,
      password: props.password,
      type: props.type,
      points: props.points,
      location: props.location,
      fromTime: props.fromTime,
      toTime: props.toTime,
      createdBy: props.createdBy,
      shiftsEvent: props.shiftsEvent,
      numShifts: props.numShifts,
      eventID: props.eventKey,
    };
    history.push("/manager", { eventProps: eventData });
  };

  const deleteEvent = () => {
    let ans = window.confirm(
      "Are you sure you want to delete this event? This action cannot be undone.",
    );
    if (!ans) {
      return;
    }
    axios
      .delete("/events/deleteEvent", {
        params: {
          eventID: props.eventKey,
        },
      })
      .then((res) => {
        window.location.reload();
      });
    axios.post("/log/createLogItem", {
      params: {
        userID: props.auth.user.id,
        name: props.auth.user.fname + " " + props.auth.user.lname,
        type: "Reset",
        time: new Date(Date.now()),
        Description: `deleted event ${props.name}.`,
      },
    });
  };

  return (
    <div className="EventCards">
      <Container>
        <Card
          border={badgeIcon[props.type]}
          style={{ borderWidth: "5px" }}
          className="p-3 m-4 rounded shadow"
        >
          <Card.Body>
            <Card.Title>
              {props.name}
              <Badge bg={badgeIcon[props.type]} className="m-2">
                {props.type}
              </Badge>
            </Card.Title>
            <Row>
              <Col>
                <p>
                  <b>Location:</b> {props.location}
                </p>
              </Col>
              <Col>
                <div>
                  {props.type === "Meeting" ? (
                    <p></p>
                  ) : (
                    <p>
                      <b>Points:</b> {props.points}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b>From:</b> {fromDate}
                </p>
              </Col>
              <Col>
                <p>
                  <b>To:</b> {toDate}
                </p>
              </Col>
            </Row>
            <Card.Text>{props.description}</Card.Text>
            <Form noValidate onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Password</b>
                </Form.Label>
                <Form.Control
                  type="password"
                  required
                  id="password"
                  placeholder="Event password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  tabIndex={1}
                  // isInvalid={!!error.email || !!error.emailnotfound}
                  // className={classnames("", {
                  //   invalid: error.email || error.emailnotfound,
                  // })}
                />
                <Form.Control.Feedback type="invalid">
                  {/* {error.email}
              {error.emailnotfound} */}
                </Form.Control.Feedback>
              </Form.Group>
              {props.shiftsEvent && (
                <Row>
                  <Col>
                    {/* Number of shifts worked: {props.numShifts} */}
                    <Form.Label>
                      <b>Number of shifts worked: </b>
                      {numShifts}
                    </Form.Label>
                    <Form.Range
                      onChange={(e) => {
                        setNumShifts(e.target.value);
                      }}
                      min={1}
                      max={props.numShifts}
                      value={numShifts}
                    />
                  </Col>
                </Row>
              )}
              <Button variant="primary" type="submit">
                Sign into event
              </Button>

              {props.canEdit && (
                <div>
                  <br></br>

                  <Button className="btn-success" onClick={() => editEvent()}>
                    Edit
                  </Button>

                  <div style={{ float: "right" }}>
                    <Button
                      className="btn-danger"
                      onClick={() => deleteEvent()}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

EventCards.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EventCards);
